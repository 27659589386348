@font-face {
  font-family: 'Outer Sans';
  font-weight: 700;
  font-style: normal;
  src: url('@src/assets/fonts/outerSans/outerSansBold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Outer Sans';
  font-weight: 400;
  font-style: normal;
  src: url('@src/assets/fonts/outerSans/outerSansRegular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Outer Sans';
  font-weight: 300;
  font-style: normal;
  src: url('@src/assets/fonts/outerSans/outerSansThin.otf') format('opentype');
  font-display: swap;
}
